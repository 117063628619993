
<template>
  <div class="container">
    <div class="row justify-content-center" >
        <div class="col-md-8 col-lg-6 col-xl-5 d-flex align-items-center justify-content-center flex-column mx-auto">
            <div class="card shadow-none bg-transparent">
                <div class="card-body p-4">
                    <div class="text-center mt-4">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: rgba(255, 255, 255, 0); display: block; shape-rendering: auto;" width="150px" height="150px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <g transform="rotate(0 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e80a0a">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(30 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e80a0a">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(60 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e80a0a">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(90 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e80a0a">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(120 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e80a0a">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(150 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e80a0a">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(180 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e80a0a">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(210 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e80a0a">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(240 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e80a0a">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(270 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e80a0a">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(300 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e80a0a">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
                                </rect>
                            </g><g transform="rotate(330 50 50)">
                                <rect x="47" y="24" rx="3" ry="6" width="6" height="12" fill="#e80a0a">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
                                </rect>
                            </g>
                        </svg>
                        <h5 class="mt-3 mb-1">Please wait!</h5>
                        <p class="text-muted small mb-3">Authentication in progress ...</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
    computed:{
        fallbackUrl(){
           return this.absoluteUrl(`/login?intended_url=${location.origin}${this.$route.query.intended_url}`)
        }
    },
    async created(){
        if(!this.$route.query.token){
          location.replace(this.fallbackUrl);return
        }
        let token = JSON.parse(atob(this.$route.query.token));
        if(typeof token !== 'object' && token === null){
          location.replace(this.fallbackUrl);return
        }
        let formData = new FormData();
        formData.append('data', JSON.stringify(token));
        this.$http.post(this.absoluteUrl('/api/auto-login'), formData)
        .then((response) => {
            if(response.data.success){
                this.$store.commit("SET_AUTH_TOKEN", response.data.data)
                location = `${this.$route.query.intended_url}`;
            }else{
                location.replace(this.fallbackUrl)
            }
        })
    }
}

</script>
